import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.sub";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
import _defineProperty from "/Users/suifeng/Desktop/projects/2023/sdMeiYe/sdMeiYeAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { menuList, roleAdd } from '@/api/apis';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  props: {},
  data: function data() {
    return {
      curTarget: null,
      dialogVisible: false,
      loading: false,
      ruleForm: {
        role_name: '',
        rules: [],
        level: 0
      },
      rules: {
        role_name: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'change'
        }]
      },
      data: [],
      defaultProps: {
        children: 'sub',
        label: 'menu_name'
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (!val) {
        this.clearForm();
      }
    }
  },
  created: function created() {
    this.initData = this.$g.fun.deepCopy(this.ruleForm);
    this.getMenuList();
  },
  computed: _objectSpread({}, mapState('d2admin', {
    info: function info(state) {
      return state.user.info;
    }
  })),
  methods: _objectSpread({}, mapActions('d2admin/user', ['set']), {
    hideCreator: function hideCreator(res) {
      var loopDeep = function loopDeep(ary) {
        for (var i in ary) {
          var item = ary[i];
          loopDeep(item.sub);
        }
      };

      loopDeep(res);
    },
    getMenuList: function getMenuList() {
      var _this = this;

      menuList({
        is_show: 0
      }).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.hideCreator(res);

                _this.data = res;

              case 2:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    clearForm: function clearForm() {
      this.ruleForm = this.$g.fun.deepCopy(this.initData);
    },
    show: function show() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.curTarget = data;
      this.dialogVisible = true;

      if (this.curTarget) {
        this.ruleForm = this.curTarget;
      }
    },
    checkChange: function checkChange() {
      this.ruleForm.rules = this.$refs.tree.getCheckedKeys();
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      this.$refs['ruleForm'].validate(function (valid) {
        if (!valid) {
          return;
        }

        var params = {
          role_name: _this2.ruleForm.role_name,
          level: 0,
          rules: _this2.ruleForm.rules
        };

        if (_this2.curTarget) {
          params.id = _this2.curTarget.id;
        }

        roleAdd(params).then(function _callee2(res) {
          return regeneratorRuntime.async(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _this2.$message({
                    type: 'success',
                    message: _this2.curTarget ? '编辑成功' : '添加成功!'
                  });

                  if (_this2.info.roles == _this2.ruleForm.id) {
                    //是当前登录的角色
                    _this2.info.t_system_role.rules = _this2.ruleForm.rules;

                    _this2.set(_this2.info);
                  }

                  _this2.dialogVisible = false;
                  _this2.loading = false;

                  _this2.clearForm();

                  _this2.$emit('reloadData'); //更新缓存


                case 6:
                case "end":
                  return _context2.stop();
              }
            }
          });
        });
      });
    }
  })
};