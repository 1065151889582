var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, size: "mini", "label-position": "top" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "级别类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.bonusMode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bonusMode", $$v)
                        },
                        expression: "form.bonusMode"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("等级")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("资格")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "奖励人的等级" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.isAfterGrade,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "isAfterGrade", $$v)
                        },
                        expression: "form.isAfterGrade"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("使用报单前的的等级")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("使用报单后的等级")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "danger" },
                  on: { click: _vm.createFactor }
                },
                [_vm._v("生成奖励配置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "success" },
                  on: { click: _vm.clearFactorData }
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini" },
                  on: { click: _vm.clickBatchSet }
                },
                [_vm._v("批量跟第一条相同")]
              )
            ],
            1
          ),
          _vm._l(_vm.form.factorList, function(item, index) {
            return _c(
              "el-card",
              {
                staticStyle: { "margin-top": "10px" },
                attrs: { shadow: "never" }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "header" }, slot: "header" },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          "margin-right": "10px"
                        }
                      },
                      [_vm._v(_vm._s("奖励会员等级：" + item.gradeName))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right" },
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.clickDelFactor(index)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "factorList" },
                  _vm._l(item.rateList, function(factor, subIndex) {
                    return _c(
                      "div",
                      { staticClass: "factorItem" },
                      [
                        _c("span", { staticStyle: { "font-size": "13px" } }, [
                          _vm._v(_vm._s("第" + (subIndex + 1) + "层"))
                        ]),
                        _c("el-input-number", {
                          staticStyle: { width: "150px", margin: "0 10px" },
                          attrs: { size: "mini", min: 0 },
                          model: {
                            value: factor.rate,
                            callback: function($$v) {
                              _vm.$set(factor, "rate", $$v)
                            },
                            expression: "factor.rate"
                          }
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.clickDelLayer(index, subIndex)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.clickAddLayer(index)
                      }
                    }
                  },
                  [_vm._v("添加层")]
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }