import { render, staticRenderFns } from "./regConfig.vue?vue&type=template&id=9dfa7df6&scoped=true&"
import script from "./regConfig.vue?vue&type=script&lang=js&"
export * from "./regConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dfa7df6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/suifeng/Desktop/projects/2023/sdMeiYe/sdMeiYeAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9dfa7df6')) {
      api.createRecord('9dfa7df6', component.options)
    } else {
      api.reload('9dfa7df6', component.options)
    }
    module.hot.accept("./regConfig.vue?vue&type=template&id=9dfa7df6&scoped=true&", function () {
      api.rerender('9dfa7df6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/sysconfig/styleConfig/regConfig.vue"
export default component.exports