import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import "regenerator-runtime/runtime";
import _defineProperty from "/Users/suifeng/Desktop/projects/2023/sdMeiYe/sdMeiYeAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
import setting from "@/setting";
import { systemTempList } from '../../../../api/apis';
import store from "@/store/index";
export default {
  name: 'd2-header-color',
  data: function data() {
    return {
      systemList: [],
      showSelect: false,
      selectSystemId: ''
    };
  },
  computed: _objectSpread({}, mapState('common/system', ['systemId'])),
  mounted: function mounted() {
    var _this = this;

    this.selectSystemId = this.systemId;
    systemTempList({}).then(function _callee(res) {
      var data, i;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              data = res;
              _context.t0 = regeneratorRuntime.keys(data);

            case 2:
              if ((_context.t1 = _context.t0()).done) {
                _context.next = 9;
                break;
              }

              i = _context.t1.value;

              if (!(data[i].status === 0)) {
                _context.next = 6;
                break;
              }

              return _context.abrupt("continue", 2);

            case 6:
              _this.systemList.push(data[i]);

              _context.next = 2;
              break;

            case 9:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: _objectSpread({}, mapActions({
    setSystemId: 'common/system/set'
  }), {
    systemName: function systemName() {
      for (var i = 0; i < this.systemList.length; i++) {
        if (this.systemList[i].id === this.systemId) {
          return this.systemList[i].name;
        }
      }

      return '';
    },
    clickSubmit: function clickSubmit() {
      this.setSystemId(this.selectSystemId);
      var loading = this.$loading({
        lock: true,
        text: '系统切换中，请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    },
    clickSys: function clickSys() {
      this.showSelect = true;
    }
  })
};