import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import configInfo from './configInfo';
import productGradePrice from './productGradePrice';
import { configGradeList, productAdd, productBatchShow, productCategory, productDel, productList, configLevelList } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    configInfo: configInfo,
    productGradePrice: productGradePrice
  },
  data: function data() {
    var _this = this;

    return {
      activeNames: 'search',
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": true,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "按分类",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_2"]
        }, {
          "children": [{
            "field": [{
              "desc": "产品名称",
              "key": "store_name",
              "value": ""
            }, {
              "desc": "产品编码",
              "key": "code",
              "value": ""
            }],
            "placeholder": "请输入名称",
            "type": "input-complex",
            "operator": []
          }, {
            "field": [{
              "desc": "产品库存",
              "key": "stock"
            }],
            "placeholder": "请输入",
            "type": "input-mul-complex",
            "operator": []
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "is_show",
              "value": "1"
            }],
            "title": "销售中",
            "type": "button"
          }, {
            "field": [{
              "key": "is_show",
              "value": "0"
            }],
            "title": "未销售",
            "type": "button"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "是否销售",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 4,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "上架日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_4"]
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 5,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "销售结束日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_5"]
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "defaultExpandAll": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }, {
          "id": "",
          "tag": "default",
          "title": "批量上架",
          "type": "danger"
        }, {
          "id": "",
          "tag": "default",
          "title": "批量下架",
          "type": "danger"
        }],
        "id": "id_1001",
        "isGroup": true,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }, {
          "id": "",
          "tag": "default",
          "title": "套餐/赠品配置",
          "type": "success"
        }, {
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "等级价格",
          "type": null
        }],
        "rowKey": "",
        "showPagination": true,
        "showSelection": true,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableGroupProps": [{
          "id": 6,
          "name": "基础信息"
        }, {
          "id": 7,
          "name": "商品图片"
        }, {
          "id": 17,
          "name": "详细说明"
        }],
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品ID",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "60",
          "prop": "id"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "是否在首页展示商品",
          "detail": 1,
          "groupId": "6",
          "label": "首页展示",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "70",
          "prop": "is_best"
        }, {
          "add": 1,
          "defaultValue": 2,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品类型",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "prop": "product_type"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "无等级购买产品的价格",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品价格",
          "required": 1,
          "rowAddField": "",
          "rules": [{
            "name": "money"
          }],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "price"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品PV",
          "required": 1,
          "rules": [{
            "name": "decimal"
          }],
          "show": 1,
          "type": "text",
          "width": "70",
          "prop": "pv"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "建议尺寸640x320 不超过256kb",
          "detail": 1,
          "excel": 1,
          "groupId": "7",
          "label": "产品主图",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "image",
          "width": "100",
          "prop": "image",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 9,
            "key": "image",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 1,
          "defaultValue": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "是否销售",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "70",
          "prop": "is_show"
        }, {
          "add": 1,
          "desc": "多个以逗号分割",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品编号",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "70",
          "prop": "code"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品名称",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "120",
          "prop": "store_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品简称",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "120",
          "prop": "shrink_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "开始销售日期",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "datetime",
          "width": "90",
          "prop": "start_date"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "结束销售日期",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "datetime",
          "width": "90",
          "prop": "end_date"
        }, {
          "add": 1,
          "defaultValue": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "开启套餐",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "is_package"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "套餐信息",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "120",
          "prop": "package_info"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "开启赠品",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "is_gift"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "赠品信息",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "120",
          "prop": "gift_info"
        }, {
          "add": 1,
          "defaultValue": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品销量",
          "required": 0,
          "rowAddField": "",
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "sales"
        }, {
          "add": 1,
          "defaultValue": 999999,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品库存",
          "required": 1,
          "rowAddField": "",
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "stock"
        }, {
          "add": 1,
          "defaultValue": null,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品分类",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "cate_id"
        }, {
          "add": 1,
          "desc": "最多64个字符",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "产品简介",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "150",
          "prop": "store_info"
        }, {
          "add": 1,
          "defaultValue": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "排序",
          "required": 0,
          "rowAddField": "",
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "sort"
        }, {
          "add": 1,
          "desc": "建议尺寸640x320 不超过256kb 最多上传9个",
          "detail": 1,
          "excel": 1,
          "groupId": "17",
          "label": "产品描述",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "images",
          "width": 160,
          "prop": "description",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 11,
            "key": "description",
            "maxNum": 9,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "创建时间",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 0,
          "defaultValue": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "6",
          "label": "是否追购产品",
          "required": 0,
          "rules": [],
          "show": 0,
          "type": "select",
          "width": "120",
          "prop": "is_reg_ex"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "购买产品时是否需要填写备注",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "是否用户备注",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "120",
          "prop": "is_remark"
        }, {
          "add": 0,
          "defaultValue": null,
          "desc": "不限制填0",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "最大可使用积分",
          "required": 0,
          "rules": [{
            "name": "integer"
          }],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "limit_fuxiao_pay"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "0为不限购",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "每单限购",
          "required": 0,
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "limit_1"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "0为不限购",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "每周限购",
          "required": 0,
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "limit_2"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "0为不限购",
          "detail": 1,
          "excel": 1,
          "groupId": "6",
          "label": "每月限购",
          "required": 0,
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "limit_3"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "6",
          "label": "活跃值",
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "active_num"
        }, {
          "label": "可购买的资格",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "不填不限制",
          "groupId": "6",
          "rules": [],
          "prop": "keyword"
        }],
        "tableName": "t_product",
        "tableRadioProps": {},
        "tableSelectProps": {
          "cate_id": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationId": "",
            "remote": true,
            "tableGroupProps": [],
            "remoteFunc": "tableFunc_select_cate_id"
          },
          "is_show": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 12,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "product_type": {
            "data": [{
              "key": 1,
              "value": "注册"
            }, {
              "key": 2,
              "value": "重购"
            }, {
              "key": 3,
              "value": "升级"
            }],
            "id": 13,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_gift": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 14,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_package": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 18,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_reg_ex": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 22,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_best": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 23,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_remark": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 24,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "keyword": {
            "multiple": 1,
            "data": [{
              "key": null,
              "value": ""
            }],
            "remote": true,
            "id": 25,
            "relation": false,
            "relationProps": [],
            "remoteFunc": "tableFunc_select_keyword"
          }
        },
        "tableSwitchProps": {},
        "title": "产品",
        "treeProps": {
          "children": "children",
          "hasChildren": "hasChildren"
        },
        "type": "tableTemplate"
      },
      remoteFunc: {
        tableFunc_select_cate_id: function tableFunc_select_cate_id(tag, relationData, resolve) {
          //筛选项模型数组
          //[{ key: "新品", value: "0" }]
          productCategory({
            page: 1,
            limit: 10000
          }).then(function _callee(res) {
            var list, data, i;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    list = _this.$tempModel.treeToList(res, 'sub', function (parent, item) {
                      if (parent) {
                        item.name = parent.name + '-' + item.cate_name;
                      } else {
                        item.name = item.cate_name;
                      }
                    });
                    data = [];

                    for (i in list) {
                      // if (list[i].pid !== 0){
                      data.push({
                        key: list[i].id,
                        value: list[i].name
                      }); // }
                    }

                    resolve(tag, data);

                  case 4:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选项模型数组
          productCategory({
            page: 1,
            limit: 10000
          }).then(function _callee2(res) {
            var list, data, i;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    list = _this.$tempModel.treeToList(res, 'sub', function (parent, item) {
                      item.name = item.cate_name;
                    });
                    data = [];

                    for (i in list) {
                      // if (list[i].pid !== 0){
                      data.push({
                        key: 'cate_id',
                        value: list[i].id,
                        desc: list[i].name
                      }); // }
                    }

                    resolve(tag, [data]);

                  case 4:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_4: function modelConditionFunc_4(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "start_date",
            value: []
          }], [{
            key: "start_date",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "start_date",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "start_date",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "start_date",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "start_date",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "start_date",
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_5: function modelConditionFunc_5(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "end_date",
            value: []
          }], [{
            key: "end_date",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "end_date",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "end_date",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "end_date",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "end_date",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "end_date",
            value: []
          }]];
          resolve(tag, data);
        },
        tableFunc_select_level: function tableFunc_select_level(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({}).then(function _callee3(res) {
            var data, i;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      data.push({
                        key: res.rows[i].tag,
                        value: res.rows[i].name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        },
        tableFunc_select_keyword: function tableFunc_select_keyword(tag, relationData, resolve) {
          //筛选项模型数组
          configLevelList({}).then(function _callee4(res) {
            var data, i;
            return regeneratorRuntime.async(function _callee4$(_context4) {
              while (1) {
                switch (_context4.prev = _context4.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      data.push({
                        key: res.rows[i].tag,
                        value: res.rows[i].name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context4.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
      }

      params.where = where;
      params.where.is_main = 1;
      productList(params).then(function _callee5(res) {
        var i, p, packages, packageInfo, giftInfo, j, item;
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                for (i in res.rows) {
                  p = res.rows[i];
                  p.cate_id = parseInt(p.cate_id);
                  packages = p.t_product_packages;
                  packageInfo = '';
                  giftInfo = '';

                  for (j in packages) {
                    item = packages[j];

                    if (item.hasOwnProperty('t_product') && item.t_product != null) {
                      if (item.is_gift === 0) {
                        packageInfo += item.t_product.store_name + 'x' + item.quantity + ' ';
                      } else {
                        giftInfo += item.t_product.store_name + 'x' + item.quantity + ' ';
                      }
                    }
                  }

                  p.package_info = packageInfo;
                  p.gift_info = giftInfo;
                }

                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
        params.is_main = 1;
      } else {
        params.is_main = 1;
      }

      this.lastSaleStartTime = params.start_date;
      this.lastSaleEndTime = params.end_date;
      var tmp = this.$tempModel.deepCopy(this.tableModel_1001.tableModel);

      for (var i in tmp) {
        var item = tmp[i];

        if (item.prop === 'start_date') {
          item.defaultValue = this.lastSaleStartTime;
        } else if (item.prop === 'end_date') {
          item.defaultValue = this.lastSaleEndTime;
        }
      }

      this.tableModel_1001.tableModel = tmp;
      productAdd(params).then(function _callee6(res) {
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context6.stop();
            }
          }
        });
      }).catch(function () {
        resolve(true);
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      productDel({
        id: ids
      }).then(function _callee7(res) {
        return regeneratorRuntime.async(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context7.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this2 = this;

      //点击列表按钮回调
      if (btnIndex === 2) {
        this.$refs['packageInfo'].show(row, function () {
          _this2.table.reloadData();
        });
      } else if (btnIndex === 3) {
        this.$refs['gradePrice'].show(row, function () {
          _this2.table.reloadData();
        });
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this3 = this;

      //点击列表头部按钮回调
      if (btnIndex === 0) {} else if (btnIndex === 1 || btnIndex === 2) {
        if (row.length <= 0) {
          this.$message({
            message: '请至少选择一个商品',
            type: 'warning'
          });
          return;
        }

        var ids = row.map(function (item) {
          return item.id;
        });
        productBatchShow({
          id: ids,
          is_show: btnIndex === 1 ? 1 : 0
        }).then(function _callee8(res) {
          return regeneratorRuntime.async(function _callee8$(_context8) {
            while (1) {
              switch (_context8.prev = _context8.next) {
                case 0:
                  _this3.$message({
                    message: '操作成功',
                    type: 'success'
                  });

                  _this3.table.reloadData();

                case 2:
                case "end":
                  return _context8.stop();
              }
            }
          });
        });
      }
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {
      //列表中switch变化事件
      if (prop === 'is_show') {
        productAdd({
          id: row.id,
          is_show: val
        }).then(function _callee9(res) {
          return regeneratorRuntime.async(function _callee9$(_context9) {
            while (1) {
              switch (_context9.prev = _context9.next) {
                case 0:
                case "end":
                  return _context9.stop();
              }
            }
          });
        });
      }
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = data.header;
        var results = data.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};