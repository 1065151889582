import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2023/sdMeiYe/sdMeiYeAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
import modifyPwd from '../../../../views/pages/setting/accountManager/modifyPwd';
import util from '../../../../libs/util';
export default {
  components: {
    modifyPwd: modifyPwd
  },
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  data: function data() {
    return {};
  },
  methods: _objectSpread({}, mapActions('d2admin/account', ['logout']), {
    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        confirm: true
      });
    },
    showPwd: function showPwd() {
      this.$refs['pwd'].show(util.cookies.get('uuid'), this.info.account, this.info.real_name);
    }
  })
};