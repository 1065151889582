import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberMove, memberPwdModify, memberRepairMoney } from '../../../../api/apis';
import aes from "@/plugin/aes.js";
import md5 from 'js-md5';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      tagList: [{
        key: 0,
        value: '登录密码'
      } // { key: 1, value: '支付密码' }
      ],
      form: {
        number: '',
        pwd: '',
        tag: 0
      },
      rules: {
        pwd: [{
          require: true,
          message: '请输入新密码',
          trigger: 'blur'
        }]
      },
      pwdKey: 'Q0Ap0LScBuQQJMr1oV9M5Ow78OgpnUVaC8Rxj1KR4ynm2fAsNrUkBBxHUiJCuio9k63T22jVH4kkGX7FCQgoLDIT6sIwhTIYiUpB7HS3ceGN2kKlbXlpyZlQwSW65FsHLNgZgUOCGbaMOdNBkL9RQJkykLCUYUJ6ECWskCUh6yTHhkz2pEvPWQA7otiUTHoNkkgWB73W6WE7XlgzkOkG3TAM2zLrzN1tiMHmc7ybKQyww2tL0emskaAJdidj9wJzROoOljd38r8S3OxUCXryjwh77l36Zd6r1xVc8K8Gtjvcs07VQQFoIcbvktM88gzwkSh9bmpGDjk7QEF20uwO9N078R2GJGMJ4KkKU4kkXwUlAkIilkbVDcFHmIMpi8xxo2UoHKIlrdTUlcDOKO52CgzTy09kHA1VgZ06f4mk85MQx3jsJvCDZIemmyMr2e3fx87e6vy3CGwKkBLQzzFLMPfD0mhZXZAtpKZXSS1anQMQaP2aBCFkZxz0l3GpyUsh'
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(row, finish) {
      this.dialogVisible = true;
      this.form.number = row.number;
      this.finish = finish;
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      memberPwdModify({
        number: this.form.number,
        pwd: md5(this.form.pwd + this.pwdKey),
        tag: this.form.tag
      }).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.$message({
                  message: '修改成功',
                  type: 'success'
                });

                _this.dialogVisible = false;

                _this.finish();

              case 3:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    }
  }
};